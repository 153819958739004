import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

const BlogPage = ({ data: { microcmsBlogs } }) => (
  <Layout>
    <Seo
      title={microcmsBlogs.title}
      description={microcmsBlogs.description}
      image={microcmsBlogs.eyecatch.url}
    />
    <h1>{microcmsBlogs.title}</h1>
    <a href="https://twitter.com/share?ref_src=twsrc%5Etfw" class="twitter-share-button" data-lang="ja" data-show-count="false">Tweet</a><script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
    <div
      dangerouslySetInnerHTML={{
        __html: `${microcmsBlogs.content}`,
      }}
    />
    <a href="https://twitter.com/share?ref_src=twsrc%5Etfw" class="twitter-share-button" data-lang="ja" data-show-count="false">Tweet</a><script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
  </Layout>
)

export default BlogPage

export const query = graphql`
  query($blogsId: String!) {
    microcmsBlogs(blogsId: { eq: $blogsId }) {
      blogsId
      title
      content
      description
      eyecatch {
        url
      }
    }
  }
`