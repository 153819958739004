import * as React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = ({ data: { allMicrocmsBlogs } }) => (
  <Layout>
    <Seo title="ABの雑記ブログ" />
    <a href="https://twitter.com/ababupdownba?ref_src=twsrc%5Etfw" class="twitter-follow-button" data-show-count="false">Follow @ababupdownba</a><script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
    <ul>
      {allMicrocmsBlogs.edges.map(({ node }) => (
        <li key="node.blogsId">
          <Link to={`/blog/${node.blogsId}`}>{node.title}</Link>
        </li>
      ))}
    </ul>
  </Layout>
)

export default IndexPage

export const query = graphql`
  query MyQuery {
    allMicrocmsBlogs {
      edges {
        node {
          blogsId
          title
        }
      }
    }
  }
`